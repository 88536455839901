import CloudObject, { CloudAccess } from "@/scripts-core/cloud/CloudObject";
import { Support } from "possibleme-db";
import { fromMillis, fillDays } from "possibleme-db/lib/util/DateHelper";

const ModelAccess : CloudAccess ={
    getAccessName(){return "scripts/possibleme/ModelUtil.ts"}
}

export function querySupportDates(supports : CloudObject<Support>[]): Date[] {
    const array : Date[] = []

    for(let i=0;i < supports.length; i++){
        const support : Support = supports[i].value(ModelAccess);
        const start = fromMillis(support.dateStart);
        const end = fromMillis(support.dateEnd);

        const between = fillDays(start, end);
        for(let i=0; i < between.length; i++)
            array.push(between[i]);
    }
    return array;
}