
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { Support } from "possibleme-db";
import { Prop } from "vue-property-decorator"
import * as dateutil from "possibleme-db/lib/util/DateHelper"

export default class ModelEvent extends FireComponent {
    @Prop()
    support! : CloudObject<Support>
    util = dateutil;

    componentClass = () => "possible-model-event"
    onUserReady(uid: string | null): void {
        return;
    }
    displayProvider(){
        return "this is a provider"
    }
    displayDate(){
        const support = this.support.value(this);
        return dateutil.displayDate(support.dateStart);
    }
    displayStart(){
        const support = this.support.value(this);
        return dateutil.displayTime(support.dateStart);
    }
    displayEnd(){
        const support = this.support.value(this);
        return dateutil.displayTime(support.dateEnd);
    }
    displayEvent(){
        const support = this.support.value(this);
        return `${dateutil.displayDateTime(support.dateStart)}   ${dateutil.displayDateTime(support.dateEnd)}`
    }
    displayLocation(){
        const support = this.support.value(this);
    
        return `${dateutil.displayDateTime(support.dateStart)}   ${dateutil.displayDateTime(support.dateEnd)}`
    }
}
