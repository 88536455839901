<template>
    <div class='event-content'>
        <h3>{{support.object.name}}</h3>
        <div>
            <div class='item'>
                <span class='material-icons'>event</span>
                <div class='p-2 pr-3'>{{displayDate()}}</div>
                <div class='p-2'>{{displayStart()}} - {{displayEnd()}}</div>
            </div>
            <div class='item'>
                <span class='material-icons'>favorite</span>
                <div class='p-2'>{{support.object.provider.providerName}}</div>
            </div>
            <div class='item'>
                <span class='material-icons'>phone</span>
                <div class='p-2'>{{support.object.provider.providerPhoneNo}}</div>
            </div>
            
            
        </div>
        <div v-if="support.object.location.address != ''" class='content-location'>
             <span class='material-icons'>place</span>
             <div   class='p-2'>{{support.object.location.address}}</div>
        </div>
        <div class='app-divider'></div>
    </div>
</template>

<script lang="ts">
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { Support } from "possibleme-db";
import { Prop } from "vue-property-decorator"
import * as dateutil from "possibleme-db/lib/util/DateHelper"

export default class ModelEvent extends FireComponent {
    @Prop()
    support! : CloudObject<Support>
    util = dateutil;

    componentClass = () => "possible-model-event"
    onUserReady(uid: string | null): void {
        return;
    }
    displayProvider(){
        return "this is a provider"
    }
    displayDate(){
        const support = this.support.value(this);
        return dateutil.displayDate(support.dateStart);
    }
    displayStart(){
        const support = this.support.value(this);
        return dateutil.displayTime(support.dateStart);
    }
    displayEnd(){
        const support = this.support.value(this);
        return dateutil.displayTime(support.dateEnd);
    }
    displayEvent(){
        const support = this.support.value(this);
        return `${dateutil.displayDateTime(support.dateStart)}   ${dateutil.displayDateTime(support.dateEnd)}`
    }
    displayLocation(){
        const support = this.support.value(this);
    
        return `${dateutil.displayDateTime(support.dateStart)}   ${dateutil.displayDateTime(support.dateEnd)}`
    }
}
</script>

<style scoped>
    .event-content {
        padding-top: 1.5rem; 
    }
    .content-date {
        display: flex;
    }
    .content-location {
        display: flex;
    }

    .item {
        display: flex;
    }
</style>
    