
import { Watch } from "vue-property-decorator"
import { Options } from "vue-class-component"
import FireComponent from "@/components/phoenix/core/FireComponent.vue";
import * as DateHelp from "possibleme-db/lib/util/DateHelper"
import * as VClient from "@/scripts-possibleme/client/PossibleClient"
import ModelEvent from "@/components/possibleme/model/ModelEvent.vue"
import CloudObject from "@/scripts-core/cloud/CloudObject";
import { Support } from "possibleme-db"
import * as VCal from "@/scripts-core/util/VCalendarUtil"
import { querySupportDates } from "@/scripts-possibleme/util/ModelUtil"
import PlanBanner from "@/components/possibleme/PlanBanner.vue"
import { getManagedUser } from "@/possibleme/Api";
import { logEventPageView, PAGE_DASHBOARD } from "@/possibleme/PossibleAnalytics";

const TextShowing = "Showing appointments on"

@Options({
    components : {
        ModelEvent,
        PlanBanner
    }
})
export default class VueDashboard extends FireComponent {
    date = new Date();
    

    dateRange : any = null

    attributes : VCal.VDot[] = [];

    today = "N/A"
    supports : CloudObject<Support>[] = []
    todayInterval = DateHelp.intervalToday();

    firstname : string | null = null
    lastname : string | null = null

    timeNow = new Date();
    displayHead = "Today"
    displayTime = DateHelp.displayDate(this.timeNow);

    timeTomorrow = DateHelp.displayDate(DateHelp.addDays(new Date(), 1));



    @Watch("dateRange")
    onRange(){
        return;
    }

    @Watch("date")
    onCalendar(){
        try{
            this.todayInterval = DateHelp.intervalDate(this.date);
            this.today = DateHelp.intervalStamp(this.todayInterval);
            this.updateSupports();

            const dateDisplay = DateHelp.displayDate(this.date)

            if(DateHelp.displayDate(new Date()) === dateDisplay){
                this.displayHead = "Today"
                this.displayTime = DateHelp.displayDate(new Date());
            }
            else if(dateDisplay == this.timeTomorrow){
                this.displayHead = "Tomorrow"
                this.displayTime = DateHelp.displayDate(this.date);
            }
            else {
                this.displayTime = DateHelp.displayDate(this.date);
                this.displayHead = TextShowing
            }

            
        }catch(e){
            console.error(e);
        }
    }

    onSupports(supports : CloudObject<Support>[] | null){
        if(!supports)return;

        const allDates = querySupportDates(supports);
        this.setCalendarDots(allDates);
    
        this.supports = supports.filter(item =>{
            const isupport = item.value(this);
            const between = DateHelp.fillDays(DateHelp.fromMillis(isupport.dateStart), DateHelp.fromMillis(isupport.dateEnd));

            for(let i=0; i < between.length; i++){
                if(DateHelp.dateInside(between[i],this.todayInterval))
                    return true;
            }
            return false;
        });
    }

    updateSupports(){
        VClient.subscribeCurrentSupports(this.getContext(), this.onSupports, "single");
    }

    componentClass = ()=> "possiblevue-dashboard"

    mounted(){
        logEventPageView(PAGE_DASHBOARD)
        this.today = DateHelp.intervalStamp(DateHelp.intervalToday());
        const cal = this.$refs['calendar'] as any
    }
  
    onUserReady() : void {
        VClient.subscribeCurrentSupports(this.getContext(), this.onSupports);
        VClient.subscribeCurrentPlan(this.getContext(), async sub => {
            //this.firstname = VClient.getManagedUser();
            //load up user name
            
            const mUserId = sub?.value(this).managedUser;
            if(!mUserId){
                //no manager user load regular name
                const client = VClient.getUser();
                if(client){
                    this.firstname = client.value(this).firstname ?? null
                    this.lastname = client.value(this).lastname ?? null
                }
            }
            else {
                //load other name
                const mUser = await getManagedUser(this, mUserId);
                this.firstname = mUser?.value(this).firstname ?? null
                this.lastname = mUser?.value(this).lastname ?? null
            }
        })
    }

    setCalendarDots(dates: Date[]){
        const dots = VCal.convertAttributes(dates);
        this.attributes = [...dots];
        return;
    }
}
