export type VFill = "solid"

export type VDot = {
    key : string,
    dot? : boolean | any,
    dates : Date,
    highlight : {
        color : string,
        filleMode: VFill
    } | boolean | string | any
}
export function convertAttributes(dates : Date[]):VDot[]{
    const list : VDot[] = [];

    const nowDot : VDot = {
        key : "now",
        dates : new Date(),
        highlight : {
            style : {
                //'background-color' : '#f83475',
                color : 'white'
            },
            fillMode : 'outline'
        }
    }
    list.push(nowDot);

    for(let i=0;i < dates.length; i++){
        const newDot : VDot = {
            key : `date:${i}`,
            dates : dates[i],
            dot : true,
            highlight : {
                color : 'green',
                filleMode : 'solid'
            }
        }
        list.push(newDot);
    }
    return list;
}